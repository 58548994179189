import Vue from 'vue'
import App from './App.vue'
import router from './router/index';
import axios from 'axios'
import Vant from 'vant'
import '../node_modules/vant/lib/index.css'
import { Uploader } from 'vant';

Vue.config.productionTip = false;

Vue.use(Vant).use(Uploader)
Vue.prototype.$axios = axios;


new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})

